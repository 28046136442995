import React, { useState, useRef } from "react";
import { supabase } from "../service/supabaseClient";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

export default function Auth() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordLogin, setPasswordLogin] = useState(true);
    const [invalidPass, setInvalidPass] = useState(false);
    const toast = useRef(null);

    const handleLogin = async (email) => {
        try {
            setLoading(true);
            // const { error } = await supabase.auth.signIn({ email, password });
            const { user, error } = await supabase.auth.signIn({
                email: email,
                password: password,
            });
            if (error) {
                setInvalidPass(true);
                setPasswordLogin(false);
            } else {
                toast.current.show({ severity: "success", summary: "Proverite mail", detail: "Kliknite na link u mailu", life: 3000 });
            }
        } catch (error) {
            alert(error.error_description || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordReset = async (email) => {
        try {
            setLoading(true);
            // const { error } = await supabase.auth.signIn({ email, password });
            const { data, error } = await supabase.auth.api.resetPasswordForEmail(email, { redirectTo: "/new-password" });
            if (error) {
                throw error;
                // setInvalidPass(true);
            }
            if (passwordLogin) {
                toast.current.show({ severity: "success", summary: "Proverite mail", detail: "Kliknite na link u mailu", life: 3000 });
            } else {
                toast.current.show({ severity: "success", summary: "Proverite mail", detail: "Kliknite na link u mailu", life: 3000 });
            }
        } catch (error) {
            alert(error.error_description || error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-column ">
            <div className="flex align-items-center justify-content-center ">
                <Toast ref={toast} />
                <div className="surface-card p-4 m-4 mt-8 shadow-2 border-round w-full lg:w-6">
                    <div className="text-center mb-5">
                        {/* <img src="assets/layout/images/logo-akustik.png" alt="hyper" height={100} className="mb-3" /> */}
                        <div className="text-900 text-3xl font-medium mb-3">Apartmani</div>
                        {/* <span className="text-600 font-medium line-height-3">Don't have an account?</span> */}
                        {/* <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> */}
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-900 font-medium mb-2">
                            Email
                        </label>
                        <InputText id="email" type="text" className="w-full mb-3 p-3 " value={email} onChange={(e) => setEmail(e.target.value)} />

                        {passwordLogin && (
                            <React.Fragment>
                                <label htmlFor="password" className="block text-900 font-medium mb-2">
                                    Lozinka
                                </label>
                                <InputText id="password" type="password" className="w-full  mb-3 p-3" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </React.Fragment>
                        )}

                        {!invalidPass && (
                            <Button
                                label={passwordLogin ? "Prijavi me" : "Pošalji mi magični link"}
                                className="w-full "
                                icon={loading ? "pi pi-spin pi-spinner" : ""}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLogin(email);
                                }}
                            />
                        )}

                        {passwordLogin && email && (
                            <Button
                                label="Resetuj lozinku"
                                className="w-full p-button p-button-link "
                                style={{ marginTop: "1rem" }}
                                icon={loading ? "pi pi-spin pi-spinner" : ""}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePasswordReset(email);
                                }}
                            />
                        )}
                    </div>
                    {!passwordLogin && (
                        <div className="flex align-items-center justify-content-between mt-2">
                            <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" onClick={(e) => setPasswordLogin(true)}>
                                Prijavi se upotrebom lozinke.
                            </a>
                        </div>
                    )}
                    {passwordLogin && (
                        <div className="flex align-items-center justify-content-between mt-2">
                            <a
                                className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                                onClick={(e) => {
                                    setPasswordLogin(false);
                                    setInvalidPass(false);
                                }}
                            >
                                Prijavi se upotrebom magičnog linka.
                            </a>
                        </div>
                    )}
                </div>
                {/* div prikazuje qr img centrirana po sred ekrana */}
            </div>
            <div className="text-center mt-4 flex align-items-center justify-content-center">
                <img src={"/assets/layout/images/qr.png"} alt="logo" />
            </div>
        </div>
    );
}
