import { atom, selector } from "recoil";
import { supabase } from "./supabaseClient";

export const passwordResetInProgressAtom = atom({
    key: "passwordResetInProgressAtom",
    default: false,
});

export const daysCountAtom = atom({
    key: "daysCountAtom",
    default: 7,
});

export const guestsCountAtom = atom({
    key: "guestsCount",
    default: 2,
});

export const dateInMinAtom = atom({
    key: "dateInMinAtom",
    default: new Date(),
});

export const dateInMaxAtom = atom({
    key: "dateInMaxAtom",
    default: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000),
});

export const alowFilterUnitSelector = selector({
    key: "alowFilterUnitSelector",
    get: async ({ get }) => {
        return get(dateInMinAtom) && get(dateInMaxAtom) ? true : false;
    },
});

export const propertiesAtom = atom({
    key: "propertiesAtom",
    default: [],
});

export const unitsAtom = atom({
    key: "unitsAtom",
    default: [],
});

export const propertiesSelector = selector({
    key: "propertiesSelector",
    get: async ({ get }) => {
        try {
            let { data, error } = await supabase.from("properties").select("*");

            if (error) {
                throw error;
            }
        } catch (error) {
            alert(error.message);
        } finally {
        }
    },
});
