import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

// state managment
import { useRecoilSnapshot, useRecoilState } from "recoil";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import { supabase } from "./service/supabaseClient";
//import Auth from "./components/Auth";
//import Account from "./components/Account";

import EmptyPage from "./pages/EmptyPage";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import Properties from "./pages/Properties";
import Units from "./pages/Units";
import Reservations from "./pages/Reservations";
import AvailableUnits from "./pages/AvailableUnits";
import Auth from "./components/Auth";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import PasswordReset from "./pages/PasswordReset";
import { passwordResetInProgressAtom } from "./service/state";

function DebugObserver() {
    const snapshot = useRecoilSnapshot();
    useEffect(() => {
        console.debug("The following atoms were modified:");
        for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
            console.debug(node.key, snapshot.getLoadable(node));
        }
    }, [snapshot]);

    return null;
}

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [displayMaximizable, setDisplayMaximizable] = useState(false);

    const [session, setSession] = useState(null);

    const [passwordResetInProgress, setPasswordResetInProgress] = useRecoilState(passwordResetInProgressAtom);

    useEffect(() => {
        setSession(supabase.auth.session());
        supabase.auth.onAuthStateChange((_event, session) => {
            if (_event === "PASSWORD_RECOVERY") {
                setPasswordResetInProgress(true);
            }

            setSession(session);
        });
    }, []);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            // label: "Pages",
            icon: "pi pi-fw pi-clone",
            items: [
                // { label: "Home", icon: "pi pi-fw pi-circle", to: "/" },
                { label: "Proveri dostupnost", icon: "pi pi-fw pi-search", to: "/available-units" },
                { label: "Rezervacije", icon: "pi pi-fw pi-calendar", to: "/reservations" },
                { label: "Vile", icon: "pi pi-fw pi-home", to: "/properties" },
                { label: "Apartmani", icon: "pi pi-fw pi-key", to: "/units" },
                { label: "Promena lozinke", icon: "pi pi-fw pi-lock", to: "/password-reset", className: passwordResetInProgress ? " p-button p-button-danger " : " hidden" },
            ],
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    const onHide = (name) => {
        setDisplayMaximizable(false);
    };

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {!session ? (
                <Auth />
            ) : (
                <React.Fragment>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                    <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        {/* <div className="absolute bottom-0 p-2 pb-4">
                            <Button
                                label="Debug"
                                className=""
                                icon="pi pi-external-link"
                                onClick={() => {
                                    setDisplayMaximizable(true);
                                }}
                            />
                            <Dialog header="Header" visible={displayMaximizable} onHide={() => setDisplayMaximizable(false)} maximizable modal style={{ width: "50vw" }} footer={false}>
                                <DebugObserver />
                                <pre>{JSON.stringify(passwordResetInProgress, false, 2)}</pre>
                            </Dialog>
                        </div> */}
                    </div>

                    <div className="layout-main-container">
                        {/* {!session ? <Auth /> : <Account key={session.user.id} session={session} />} */}

                        <div className="layout-main">
                            <Route path="/" exact component={AvailableUnits} />
                            <Route path="/properties" component={Properties} />
                            <Route path="/units" component={Units} />
                            <Route path="/reservations" component={Reservations} />
                            <Route path="/available-units" component={AvailableUnits} />
                            <Route path="/empty" component={EmptyPage} />
                            <Route path="/password-reset" component={PasswordReset} />
                        </div>

                        <AppFooter layoutColorMode={layoutColorMode} />
                    </div>
                </React.Fragment>
            )}
            {/* <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} /> */}

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;
