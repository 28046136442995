import React from "react";

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            <img src={props.layoutColorMode === "light" ? "images/logo-akustik.png" : "images/logo-akustik.png"} alt="Logo" height="20" className="mr-2" />
            by
            <span className="font-medium ml-2">Nik</span>
        </div>
    );
};
