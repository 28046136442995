import { supabase } from "./supabaseClient";

export class UnitsService {
    /**  */
    getUnits = async () => {
        let { data: units, error } = await supabase.from("units").select("*");

        if (error) return;

        return units;
    };

    /**
     *
     * @param {*} dateInMin  najraniji datum ulaska
     * @param {*} dateInMax najkasniji datum ulaska
     * @param {*} daysCount broj dana
     * @param {*} guests broj gostiju
     * @returns
     */
    getAvailableUnits = async (dateInMin, dateInMax, daysCount, guestsCount) => {
        let { data: units, error } = await supabase.rpc("available_units", {
            date_in_1: dateInMin,
            date_in_2: dateInMax,
            days_count: daysCount,
            guests: guestsCount,
        });

        if (error) return;

        return units;
    };

    getUnitsWithProperty = async () => {
        let { data: units, error } = await supabase
            .from("units")
            .select(
                `
            id,        
            title,
            description,
            property_id,
            max_guests,
            properties (
              id,
              name
            )
            `
            )
            .order("id", { ascending: true });

        if (error) return;

        return units;
    };

    createUnit = async (property) => {
        const { data, error } = await supabase.from("units").insert([{ ...property }]);
        if (error) {
            return;
        }

        return data;
    };

    saveUnit = async (property) => {
        let { data, error } = await supabase.from("units").upsert({ ...property });
        if (error) {
            return;
        }

        return data;
    };

    deleteUnit = async (propertiyId) => {
        let { data, error } = await supabase.from("units").delete().eq("id", propertiyId);

        if (error) {
            return;
        }

        return data;
    };
}
