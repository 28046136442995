import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { supabase } from "./service/supabaseClient";

export const AppTopbar = (props) => {
    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === "light" ? "assets/layout/images/logo-akustik.png" : "assets/layout/images/logo-white.svg"} alt="logo" />
                <span>Apartmani</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                <li>
                    <button onClick={() => supabase.auth.signOut()} className="p-link layout-topbar-button">
                        <i className="pi pi-sign-in" />
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
