import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { UnitsService } from "../service/UnitsService";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { useRecoilState } from "recoil";
import { daysCountAtom, guestsCountAtom, dateInMinAtom, dateInMaxAtom, alowFilterUnitSelector } from "../service/state";
import { Suspense } from "react/cjs/react.production.min";
import { ReservationsService } from "../service/ReservationsService";
import { supabase } from "../service/supabaseClient";
import moment from "moment";

const AvailableUnits = () => {
    let emptyReservation = {
        //id: null,
        unit_id: null,
        start_date: null,
        end_date: null,
        guests: 1,
        name: "",
        lastname: "",
        contact: "",
        description: "",
    };

    //    let today = new Date();

    const [dateInMin, setDateInMin] = useRecoilState(dateInMinAtom);
    const [dateInMax, setDateInMax] = useRecoilState(dateInMaxAtom);
    const [guestsCount, setGuestsCount] = useRecoilState(guestsCountAtom);
    const [daysCount, setDaysCount] = useRecoilState(daysCountAtom);
    // const alowFilterUnits = useRecoilValue(alowFilterUnitSelector);

    const [reservationDialog, setReservationDialog] = useState(false);
    const [reservation, setReservation] = useState(emptyReservation);
    const [reservations, setReservations] = useState(null);
    const [units, setUnits] = useState(null);
    const unit = null;
    const [selectedUnits, setSelectedUnits] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const unitService = new UnitsService();

    const reservationService = new ReservationsService();

    useEffect(() => {
        unitService.getUnits().then((data) => {
            setUnits(data);
        });

        // inicial loading units
        // getUnits();
    }, []);

    const onCheckAvailableUnits = () => {
        unitService.getAvailableUnits(dateInMin, dateInMax, daysCount, guestsCount).then((data) => {
            setReservations(data);
        });
    };

    // const openNew = () => {
    //     setReservation(emptyReservation);
    //     setSubmitted(false);
    //     setReservationDialog(true);
    // };

    const checkIfAvailable = async () => {
        let { id, start_date, unit_id, guests, end_date } = { ...reservation };

        let { data, error } = await supabase.rpc("check_is_available", {
            date_in_1: start_date,
            for_unit_id: unit_id,
            guests_count: guests,
            days_count: 1,
            date_in_2: end_date,
            reservation_id: id ? id : 0,
        });

        if (error) console.error(error);
        else {
            if (data[0]) {
                toast.current.show({ severity: "error", summary: "Greška", detail: "Rezervaciju nije moguće kreirati. Termin nije slobodan!", life: 3000 });
                return;
            } else {
                toast.current.show({ severity: "success", summary: "Provera termina", detail: "Termin slobodan", life: 3000 });
                saveReservation();
                return;
            }
        }
    };

    const hideDialog = () => {
        setSubmitted(false);
        setReservationDialog(false);
    };

    const saveReservation = async () => {
        setSubmitted(true);

        delete reservation.unit_name;
        delete reservation.property;

        if (reservation.name.trim()) {
            reservationService
                .createReservation(reservation)
                .then((data) => {
                    console.log("stiglo", data[0]);
                    onCheckAvailableUnits();
                    if (data[0]) {
                        setReservationDialog(false);
                        setReservation(emptyReservation);
                    }
                    toast.current.show({ severity: "success", summary: "Uspešno", detail: "Rezervacija Kreirana", life: 3000 });
                })
                .catch((error) => {
                    console.log("stiglo", error);
                });
        }
    };

    const editReservation = (unit) => {
        let reservation = {
            ...unit,
            start_date: unit.start_date,
            end_date: unit.end_date,
            unit_id: unit.unit_id,
            guests: guestsCount,
            name: "",
            lastname: "",
            contact: "",
            description: "",
        };

        setReservation(reservation);
        setReservationDialog(true);
    };

    const onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || "";

        if (name === "start_date") {
            val = moment(val).format("YYYY-MM-DD");
        }
        if (name === "end_date") {
            val = moment(val).format("YYYY-MM-DD");
        }

        let _reservation = { ...reservation };
        _reservation[`${name}`] = val;

        setReservation(_reservation);
    };

    const unitBodyTemplate = (rowData) => {
        return (
            <>
                <span className="">{rowData.property}</span>
                <br></br>
                <span className="font-bold">{rowData.unit_name}</span>
            </>
        );
    };
    const periodBodyTemplate = (rowData) => {
        return (
            <span className="text-xl">
                <span className="text-500">{"od"} </span> {moment(rowData.start_date).format("DD-MM-YYYY")} <span className="text-500">{"do"} </span>
                {moment(rowData.end_date).format("DD-MM-YYYY")}
            </span>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-calendar" label="Bukiraj" className="p-button-rounded p-button-success mr-2" onClick={() => editReservation(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Moguće rezervacije</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pretraga..." />
            </span>
        </div>
    );

    const reservationDialogFooter = (
        <>
            <Button label="Zatvori" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sačuvaj" icon="pi pi-check" className="p-button-text" onClick={checkIfAvailable} />
        </>
    );

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="image-text text-green-700 font-bold">{data.unit_name}</span>
            </React.Fragment>
        );
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card p-fluid">
                    <h5>Pretraga slobodnih apartmana</h5>
                    <div className=" grid">
                        <div className="field col-6 md:col-4 xl:col-2">
                            <label htmlFor="guestsCount">Broj osoba</label>
                            <InputNumber
                                inputId="guestsCount"
                                value={guestsCount}
                                onChange={(e) => {
                                    console.log("ovde", e);
                                    setGuestsCount(e.value);
                                }}
                                mode="decimal"
                                showButtons
                                min={0}
                                max={100}
                                className={classNames("flex-auto  pr-3", { "p-invalid": submitted && !reservation.guestsCount })}
                            />
                        </div>
                        <div className="field col-6 md:col-4 xl:col-2">
                            <label htmlFor="daysCount">Broj dana</label>
                            <InputNumber
                                inputId="daysCount"
                                value={daysCount}
                                onChange={(e) => {
                                    console.log("ovde", e);
                                    setDaysCount(e.value);
                                }}
                                mode="decimal"
                                showButtons
                                min={0}
                                max={100}
                                className={classNames("flex-auto  pr-3", { "p-invalid": submitted && !reservation.deys })}
                            />
                        </div>
                        <div className="field col-6 md:col-4 xl:col-2">
                            <label htmlFor="dateInMin">Najraniji datum dolaska</label>
                            <Calendar placeholder="OD" showTime={false} value={dateInMin} onChange={(e) => setDateInMin(e.target.value)} dateFormat="yy-mm-dd" required autoFocus className={classNames("flex-auto  pr-3", { "p-invalid": submitted && !reservation.start_date })}></Calendar>
                        </div>
                        <div className="field col-6 md:col-4 xl:col-2">
                            <label htmlFor="dateInMax">Najkasniji datum dolaska</label>
                            <Calendar placeholder="DO" value={dateInMax} minDate={dateInMin} onChange={(e) => setDateInMax(e.target.value)} dateFormat="yy-mm-dd" required autoFocus className={classNames("flex-auto  pr-3", { "p-invalid": submitted && !reservation.end_date })}></Calendar>
                        </div>
                        <div className="field col  ">
                            <label htmlFor="search">Provera</label>
                            <Suspense fallback={<div>...</div>}>
                                <Button itemID="search" label="Pretraži" onClick={() => onCheckAvailableUnits()}></Button>
                            </Suspense>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <Toast ref={toast} />
                    {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                    {/* <pre>{JSON.stringify(reservation, false, 2)}</pre>
                 
                    <pre>{JSON.stringify(unit, false, 2)}</pre> */}
                    <DataTable
                        ref={dt}
                        value={reservations}
                        selection={selectedUnits}
                        onSelectionChange={(e) => setSelectedUnits(e.value)}
                        globalFilterFields={["unit_name", "property", "start_date", "end_date"]}
                        dataKey="unit_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Prikaz {first} do {last} od ukupno {totalRecords} rezultata"
                        globalFilter={globalFilter}
                        emptyMessage="Nema rezultata."
                        header={header}
                        responsiveLayout="scroll"
                        rowGroupMode="subheader"
                        groupRowsBy="unit_id"
                        sortMode="single"
                        sortField="unit_id"
                        sortOrder={1}
                        rowGroupHeaderTemplate={headerTemplate}
                    >
                        <Column field="unit_id" header="Smeštaj" sortable body={unitBodyTemplate}></Column>
                        <Column field="start_date" header="Period" sortable body={periodBodyTemplate}></Column>
                        <Column headerStyle={{ width: "14%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>
                    <Dialog visible={reservationDialog} style={{ width: "450px" }} header="Rezervacija" modal className="p-fluid" footer={reservationDialogFooter} onHide={hideDialog}>
                        {reservation.image && <img src={`assets/demo/images/reservation/${reservation.image}`} alt={reservation.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="unit_id">Od / Do</label>
                            <div className="flex">
                                <Calendar
                                    showIcon
                                    value={reservation.start_date ? new Date(reservation.start_date) : null}
                                    onChange={(e) => onInputChange(e, "start_date")}
                                    dateFormat="yy-mm-dd"
                                    required
                                    autoFocus
                                    className={classNames("flex-auto flex pr-3", { "p-invalid": submitted && !reservation.start_date })}
                                ></Calendar>
                                <Calendar
                                    showIcon
                                    value={reservation.end_date ? new Date(reservation.end_date) : null}
                                    onChange={(e) => onInputChange(e, "end_date")}
                                    dateFormat="yy-mm-dd"
                                    required
                                    autoFocus
                                    className={classNames("flex-auto flex", { "p-invalid": submitted && !reservation.end_date })}
                                ></Calendar>
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="unit_id" className="col-12 mb-2 md:col-4 md:mb-0">
                                Smeštaj
                            </label>
                            <div className="col-12 md:col-8">
                                <Dropdown
                                    value={reservation.unit_id}
                                    optionValue="id"
                                    optionLabel="title"
                                    options={units}
                                    onChange={(e) => onInputChange(e, "unit_id")}
                                    filter
                                    showClear
                                    filterBy="title"
                                    placeholder="Odaberi smeštaj"
                                    required
                                    autoFocus
                                    className={classNames("flex-auto flex", { "p-invalid": submitted && !reservation.unit_id })}
                                />
                                {submitted && !reservation.unit_id && <small className="p-invalid">Vila je obavezno polje.</small>}
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="guests" className="col-12 mb-2 md:col-4 md:mb-0">
                                Broj gostiju
                            </label>
                            <div className="col-12 md:col-8">
                                <InputNumber inputId="guests" value={reservation.guests} onValueChange={(e) => onInputChange(e, "guests")} mode="decimal" showButtons min={0} max={100} />
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="name" className="col-12 mb-2 md:col-4 md:mb-0">
                                Ime
                            </label>
                            <div className="col-12 md:col-8">
                                <InputText id="name" value={reservation.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !reservation.name })} />
                                {submitted && !reservation.name && <small className="p-invalid">Ime je obavezno polje.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="lastname" className="col-12 mb-2 md:col-4 md:mb-0">
                                Prezime
                            </label>
                            <div className="col-12 md:col-8">
                                <InputText id="lastname" value={reservation.lastname} onChange={(e) => onInputChange(e, "lastname")} />
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="contact" className="col-12 mb-2 md:col-4 md:mb-0">
                                Kontakt
                            </label>
                            <div className="col-12 md:col-8">
                                <InputText id="contact" value={reservation.contact} onChange={(e) => onInputChange(e, "contact")} required className={classNames({ "p-invalid": submitted && !reservation.contact })} />
                                {submitted && !reservation.contact && <small className="p-invalid">Ime je obavezno polje.</small>}
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="description">Napomena</label>
                            <InputTextarea id="description" value={reservation.description ? reservation.description : ""} onChange={(e) => onInputChange(e, "description")} required rows={3} cols={20} />
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AvailableUnits, comparisonFn);
