import { supabase } from "./supabaseClient";

export class PropertiesService {
    /**  */
    getProperties = async () => {
        let { data: properties, error } = await supabase.from("properties").select("*");

        if (error) return;

        return properties;
    };

    createProperties = async (property) => {
        const { data, error } = await supabase.from("properties").insert([{ ...property }]);
        if (error) {
            return;
        }

        return data;
    };

    saveProperties = async (property) => {
        let { data, error } = await supabase.from("properties").upsert({ ...property });
        if (error) {
            return;
        }

        return data;
    };

    deleteProperties = async (propertiyId) => {
        let { data, error } = await supabase.from("properties").delete().eq("id", propertiyId);

        if (error) {
            return;
        }

        return data;
    };
}
