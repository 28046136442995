import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { ReservationsService } from "../service/ReservationsService";
import { UnitsService } from "../service/UnitsService";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { supabase } from "../service/supabaseClient";
import { EventListExport } from "../components/EventListExport";
import moment from "moment";

const Reservations = () => {
    let emptyReservation = {
        //id: null,
        unit_id: null,
        start_date: null,
        end_date: null,
        guests: 1,
        name: "",
        lastname: "",
        contact: "",
        description: "",
    };

    const [reservations, setReservations] = useState(null);
    const [units, setUnits] = useState(null);
    const [reservationDialog, setReservationDialog] = useState(false);
    const [deleteReservationDialog, setDeleteReservationDialog] = useState(false);
    const [deleteReservationsDialog, setDeleteReservationsDialog] = useState(false);
    const [reservation, setReservation] = useState(emptyReservation);
    const [selectedReservations, setSelectedReservations] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const reservationService = new ReservationsService();

    const unitsService = new UnitsService();

    useEffect(() => {
        reservationService.getReservations().then((data) => {
            setReservations(data);
        });

        unitsService.getUnits().then((data) => {
            setUnits(data);
        });

        // inicial loading reservations
        // getReservations();
    }, []);

    const reloadReservations = () => {
        reservationService.getReservations().then((data) => {
            setReservations(data);
        });
    };

    const openNew = () => {
        setReservation(emptyReservation);
        setSubmitted(false);
        setReservationDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setReservationDialog(false);
    };

    const hideDeleteReservationDialog = () => {
        setDeleteReservationDialog(false);
    };

    const hideDeleteReservationsDialog = () => {
        setDeleteReservationsDialog(false);
    };

    const checkIfAvailable = async () => {
        let { id, start_date, unit_id, guests, end_date } = { ...reservation };

        let { data, error } = await supabase.rpc("check_is_available", {
            date_in_1: start_date,
            for_unit_id: unit_id,
            guests_count: guests,
            days_count: 1,
            date_in_2: end_date,
            reservation_id: id ? id : 0,
        });

        if (error) console.error(error);
        else {
            if (data[0]) {
                toast.current.show({ severity: "error", summary: "Greška", detail: "Rezervaciju nije moguće kreirati. Termin nije slobodan!", life: 3000 });
                return;
            } else {
                toast.current.show({ severity: "success", summary: "Provera termina", detail: "Termin slobodan", life: 3000 });
                saveReservation();
                return;
            }
        }
    };

    const saveReservation = async () => {
        setSubmitted(true);

        delete reservation.units;

        if (reservation.name.trim()) {
            let _reservations = [...reservations];
            let _reservation = { ...reservation };

            if (reservation.id) {
                const index = findIndexById(reservation.id);
                _reservations[index] = _reservation;
                delete reservation.properties;
                reservationService.saveReservation(reservation).then((data) => {
                    reloadReservations();
                    setReservationDialog(false);
                    setReservation(emptyReservation);
                    toast.current.show({ severity: "success", summary: "Uspešno", detail: "Rezervacija Izmenjena", life: 3000 });
                });
            } else {
                reservationService
                    .createReservation(reservation)
                    .then((data) => {
                        console.log("stiglo", data[0]);
                        if (data[0]) {
                            setReservationDialog(false);
                            setReservation(emptyReservation);
                            reloadReservations();
                        }
                        toast.current.show({ severity: "success", summary: "Uspešno", detail: "Rezervacija Kreirana", life: 3000 });
                    })
                    .catch((error) => {
                        console.log("stiglo", error);
                    });
            }
        }
    };

    const editReservation = (reservation) => {
        setReservation({ ...reservation });
        setReservationDialog(true);
    };

    const ConfirmDeleteReservation = (reservation) => {
        setReservation(reservation);
        setDeleteReservationDialog(true);
    };

    const deleteReservation = () => {
        let _reservations = reservations.filter((val) => val.id !== reservation.id);
        setReservations(_reservations);
        setDeleteReservationDialog(false);
        setReservation(emptyReservation);
        // delete reservation from db
        reservationService.deleteReservation(reservation.id).then((data) => {
            toast.current.show({ severity: "success", summary: "Uspešno", detail: "Rezervacija je obrisana", life: 3000 });
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < reservations.length; i++) {
            if (reservations[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const PotvrdaDeleteSelected = () => {
        setDeleteReservationsDialog(true);
    };

    const deleteSelectedReservations = () => {
        let _reservations = reservations.filter((val) => !selectedReservations.includes(val));
        setReservations(_reservations);
        setDeleteReservationsDialog(false);
        setSelectedReservations(null);

        // selectedReservations

        selectedReservations.forEach((element) => {
            reservationService.deleteReservation(element.id).then((data) => {
                toast.current.show({ severity: "success", summary: "Uspešno", detail: "Rezervacija je obrisana", life: 3000 });
            });
            // toast.current.show({ severity: "success", summary: "Uspešno", detail: "Rezervacije su obrisane", life: 3000 });
        });
    };

    const onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || "";

        // ako je start_date ili end_date onda malo prepakuje
        if (name === "start_date") {
            val = moment(val).format("YYYY-MM-DD");
        }
        if (name === "end_date") {
            val = moment(val).format("YYYY-MM-DD");
        }

        let _reservation = { ...reservation };
        _reservation[`${name}`] = val;

        setReservation(_reservation);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Dodaj rezervaciju" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Obriši rezervaciju" icon="pi pi-trash" className="p-button-danger mr-2" onClick={PotvrdaDeleteSelected} disabled={!selectedReservations || !selectedReservations.length} />
                    <EventListExport key={reservations} reservations={reservations}></EventListExport>
                </div>
            </React.Fragment>
        );
    };

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        );
    };

    const periodBodyTemplate = (rowData) => {
        return (
            <span>
                {rowData.start_date} <br></br> {rowData.end_date}
            </span>
        );
    };

    const contactBodyTemplate = (rowData) => {
        return (
            <span>
                {rowData.name} {rowData.lastname} <br /> {rowData.contact}
            </span>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return <p className="text-pink-400"> {rowData.description}</p>;
    };

    /** @todo Podesiti prikaz smeštaja */
    const unitBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-unit">{rowData.units.properties.name}</span>
                <br></br>
                <span className="font-bold">{rowData.units.title}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editReservation(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => ConfirmDeleteReservation(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Rezervacije</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pretraga..." />
            </span>
        </div>
    );

    const reservationDialogFooter = (
        <>
            <Button label="Zatvori" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sačuvaj" icon="pi pi-check" className="p-button-text" onClick={checkIfAvailable} />
        </>
    );
    const deleteReservationDialogFooter = (
        <>
            <Button label="Ne" icon="pi pi-times" className="p-button-text" onClick={hideDeleteReservationDialog} />
            <Button label="Da" icon="pi pi-check" className="p-button-text" onClick={deleteReservation} />
        </>
    );
    const deleteReservationsDialogFooter = (
        <>
            <Button label="Ne" icon="pi pi-times" className="p-button-text" onClick={hideDeleteReservationsDialog} />
            <Button label="Da" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedReservations} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12 ">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={reservations}
                        selection={selectedReservations}
                        onSelectionChange={(e) => setSelectedReservations(e.value)}
                        globalFilterFields={["units.title", "units.properties.name", "start_date", "end_date", "guests", "name", "lastname", "contact", "description"]}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Prikaz {first} do {last} od ukupno {totalRecords} rezultata"
                        globalFilter={globalFilter}
                        emptyMessage="Nema rezultata."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="unit_id" header="Smeštaj" sortable body={unitBodyTemplate}></Column>
                        {/* <Column field="units.properties.name" header="Vila" sortable></Column>
                        <Column field="units.title" header="Apartman" sortable></Column> */}
                        {/* <Column field="start_date" header="Od" sortable></Column>
                        <Column field="end_date" header="Do" sortable></Column> */}
                        <Column field="start_date" header="Period" sortable body={periodBodyTemplate}></Column>
                        <Column field="guests" header="Broj gostiju" sortable></Column>
                        {/* <Column field="name" header="Ime" sortable body={nameBodyTemplate}></Column>
                        <Column field="lastname" header="Prezime" sortable></Column> */}
                        <Column field="contact" header="Kontakt" sortable body={contactBodyTemplate}></Column>
                        <Column field="description" header="Napomena" sortable body={descriptionBodyTemplate}></Column>
                        <Column headerStyle={{ width: "14%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={reservationDialog} style={{ width: "450px" }} header="Rezervacija" modal className="p-fluid" footer={reservationDialogFooter} onHide={hideDialog}>
                        {reservation.image && <img src={`assets/demo/images/reservation/${reservation.image}`} alt={reservation.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="unit_id">Od / Do</label>
                            <div className="flex">
                                <Calendar
                                    showIcon
                                    value={reservation.start_date ? new Date(reservation.start_date) : null}
                                    onChange={(e) => onInputChange(e, "start_date")}
                                    dateFormat="yy-mm-dd"
                                    required
                                    autoFocus
                                    className={classNames("flex-auto flex pr-3", { "p-invalid": submitted && !reservation.start_date })}
                                ></Calendar>
                                <Calendar
                                    showIcon
                                    value={reservation.end_date ? new Date(reservation.end_date) : null}
                                    onChange={(e) => onInputChange(e, "end_date")}
                                    dateFormat="yy-mm-dd"
                                    required
                                    autoFocus
                                    className={classNames("flex-auto flex", { "p-invalid": submitted && !reservation.end_date })}
                                ></Calendar>
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="unit_id" className="col-12 mb-2 md:col-4 md:mb-0">
                                Smeštaj
                            </label>
                            <div className="col-12 md:col-8">
                                <Dropdown
                                    value={reservation.unit_id}
                                    optionValue="id"
                                    optionLabel="title"
                                    options={units}
                                    onChange={(e) => onInputChange(e, "unit_id")}
                                    filter
                                    showClear
                                    filterBy="title"
                                    placeholder="Odaberi smeštaj"
                                    required
                                    autoFocus
                                    className={classNames("flex-auto flex", { "p-invalid": submitted && !reservation.unit_id })}
                                />
                                {submitted && !reservation.unit_id && <small className="p-invalid">Vila je obavezno polje.</small>}
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="guests" className="col-12 mb-2 md:col-4 md:mb-0">
                                Broj gostiju
                            </label>
                            <div className="col-12 md:col-8">
                                <InputNumber inputId="guests" value={reservation.guests} onValueChange={(e) => onInputChange(e, "guests")} mode="decimal" showButtons min={0} max={100} />
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="name" className="col-12 mb-2 md:col-4 md:mb-0">
                                Ime
                            </label>
                            <div className="col-12 md:col-8">
                                <InputText id="name" value={reservation.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !reservation.name })} />
                                {submitted && !reservation.name && <small className="p-invalid">Ime je obavezno polje.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="lastname" className="col-12 mb-2 md:col-4 md:mb-0">
                                Prezime
                            </label>
                            <div className="col-12 md:col-8">
                                <InputText id="lastname" value={reservation.lastname} onChange={(e) => onInputChange(e, "lastname")} />
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="contact" className="col-12 mb-2 md:col-4 md:mb-0">
                                Kontakt
                            </label>
                            <div className="col-12 md:col-8">
                                <InputText id="contact" value={reservation.contact} onChange={(e) => onInputChange(e, "contact")} required className={classNames({ "p-invalid": submitted && !reservation.contact })} />
                                {submitted && !reservation.contact && <small className="p-invalid">Kontakt je obavezno polje.</small>}
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="description">Napomena</label>
                            <InputTextarea id="description" value={reservation.description ? reservation.description : ""} onChange={(e) => onInputChange(e, "description")} required rows={3} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteReservationDialog} style={{ width: "450px" }} header="Potvrda" modal footer={deleteReservationDialogFooter} onHide={hideDeleteReservationDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {reservation && (
                                <span>
                                    Potvrdi brisanje <b>{reservation.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteReservationsDialog} style={{ width: "450px" }} header="Potvrda" modal footer={deleteReservationsDialogFooter} onHide={hideDeleteReservationsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {reservation && <span>Potvrdi brisanje selektovanih rezervacija?</span>}
                        </div>
                    </Dialog>
                    {/* <pre>{JSON.stringify(reservation, false, 2)}</pre> */}
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Reservations, comparisonFn);
