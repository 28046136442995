import React from "react";
import ReactExport from "react-export-excel";
import { Button } from "primereact/button";
import moment from "moment";
import Reservations from "../pages/Reservations";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class EventListExport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reservations: false,
            download: false,
            downloadInProgres: false,
            reportName: "Izveštaj sve reyervacije",
        };

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.showIcon = this.showIcon.bind(this);
    }
    componentDidMount() {
        this.setState({ reservations: this.props.reservations });
    }

    onDownloadClick() {
        console.log(this.state.reservations);
        this.setState({ download: true });

        // this.setState({ download: false, downloadInProgres: false });
    }

    showIcon() {
        if (this.downloadInProgres) {
            return "pi  pi-spin pi-spinner";
        } else {
            return "pi pi-download";
        }
    }

    render() {
        const icon = this.state.downloadInProgres ? "pi  pi-spin pi-spinner" : "pi pi-download";
        return (
            <span>
                <Button
                    icon={icon}
                    className="p-button-success"
                    iconPos="left"
                    style={{ marginRight: "10px" }}
                    label={"Export"}
                    onClick={(e) => {
                        this.onDownloadClick();
                    }}
                ></Button>
                {this.state.download ? (
                    <React.Fragment>
                        <ExcelFile
                            filename={this.state.reportName}
                            hideElement={true}
                            element={
                                <Button
                                    icon={icon}
                                    className="p-button-success"
                                    iconPos="left"
                                    style={{ marginRight: "10px" }}
                                    label={"Export"}
                                    onClick={(e) => {
                                        this.onDownloadClick();
                                    }}
                                ></Button>
                            }
                        >
                            <ExcelSheet data={this.state.reservations} name={"Rezervacije"}>
                                <ExcelColumn label={"Ime"} value="name" />
                                <ExcelColumn label={"Prezime"} value="lastname" />
                                <ExcelColumn label={"Kontakt"} value="contact" />
                                <ExcelColumn label={"Od"} value="start_date" />
                                <ExcelColumn label={"Do"} value="end_date" />
                                <ExcelColumn label={"Broj gostiju"} value="guests" />
                                <ExcelColumn label={"Apartman"} value={(col) => col.units.title} />
                                <ExcelColumn label={"Vila"} value={(col) => col.units.properties.name} />
                                <ExcelColumn label={"Opis"} value="description" />
                            </ExcelSheet>
                        </ExcelFile>
                    </React.Fragment>
                ) : (
                    ""
                )}
            </span>
        );
    }
}
