import { supabase } from "./supabaseClient";

export class ReservationsService {
    /**  */
    getReservations = async () => {
        // let { data: reservations, error } = await supabase.from("reservations").select("*").order("id", { ascending: true });
        let { data: reservations, error } = await supabase
            .from("reservations")
            .select(
                `
            id,
            unit_id,
            start_date,
            end_date,
            guests,
            name,
            lastname,
            contact,
            description,
            units (
                id,
                title,
                property_id,
                properties (
                    id,
                    name
                  )
            )
        `
            )
            .order("id", { ascending: true });
        if (error) return;

        return reservations;
    };

    createReservation = async (property) => {
        const { data, error } = await supabase.from("reservations").insert([{ ...property }]);
        if (error) {
            return;
        }

        return data;
    };

    saveReservation = async (property) => {
        let { data, error } = await supabase.from("reservations").upsert({ ...property });
        if (error) {
            return;
        }

        return data;
    };

    deleteReservation = async (propertiyId) => {
        let { data, error } = await supabase.from("reservations").delete().eq("id", propertiyId);

        if (error) {
            return;
        }

        return data;
    };

    check;
}
