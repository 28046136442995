import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
// import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
//import { InputTextarea } from "primereact/inputtextarea";
//import { RadioButton } from "primereact/radiobutton";
//import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
//import { useRecoilState, useRecoilValue } from "recoil";
//import { propertiesAtom, propertiesSelector } from "../service/state";
import { supabase } from "../service/supabaseClient";
import { PropertiesService } from "../service/PropertiesService";

const Properties = () => {
    let emptyProperty = {
        //id: null,
        name: "",
    };

    const [properties, setProperties] = useState(null);
    // const [properties2, setProperties2] = useRecoilState(propertiesAtom);
    const [propertyDialog, setPropertyDialog] = useState(false);
    const [deletePropertyDialog, setDeletePropertyDialog] = useState(false);
    const [deletePropertiesDialog, setDeletePropertiesDialog] = useState(false);
    const [property, setProperty] = useState(emptyProperty);
    const [selectedProperties, setSelectedProperties] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const propertyService = new PropertiesService();

    async function getProperties() {
        try {
            let { data, error } = await supabase.from("properties").select("*");

            if (error) {
                throw error;
            }

            setProperties(data);
        } catch (error) {
            alert(error.message);
        } finally {
        }
    }

    useEffect(() => {
        // inicial loading properties
        getProperties();
    }, []);

    const openNew = () => {
        setProperty(emptyProperty);
        setSubmitted(false);
        setPropertyDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setPropertyDialog(false);
    };

    const hideDeletePropertyDialog = () => {
        setDeletePropertyDialog(false);
    };

    const hideDeletePropertiesDialog = () => {
        setDeletePropertiesDialog(false);
    };

    const saveProperty = async () => {
        setSubmitted(true);

        if (property.name.trim()) {
            let _properties = [...properties];
            let _property = { ...property };
            if (property.id) {
                const index = findIndexById(property.id);
                _properties[index] = _property;

                propertyService.saveProperties(property).then((data) => {
                    toast.current.show({ severity: "success", summary: "Uspešno", detail: "Vila je izmenjena", life: 3000 });
                });
            } else {
                const { data, error } = await supabase.from("properties").insert([{ ...property }]);
                if (error) {
                    toast.current.show({ severity: "error", summary: "Greška", detail: error.message, life: 3000 });
                }

                _properties.push(data[0]);
                setProperties(_properties);
                toast.current.show({ severity: "success", summary: "Uspešno", detail: "Vila je dodata", life: 3000 });
            }

            setPropertyDialog(false);
            setProperty(emptyProperty);
        }
    };

    const editProperty = (property) => {
        setProperty({ ...property });
        setPropertyDialog(true);
    };

    const confirmDeleteProperty = (property) => {
        setProperty(property);
        setDeletePropertyDialog(true);
    };

    const deleteProperty = async () => {
        // delete properti from db

        setDeletePropertyDialog(false);

        let { data, error } = await supabase.from("properties").delete().eq("id", property.id);

        if (error) {
            toast.current.show({ severity: "error", summary: "Greška", detail: "Brisanje nije uspelo", life: 3000 });
            return;
        }

        let _properties = properties.filter((val) => val.id !== property.id);
        setProperties(_properties);

        setProperty(emptyProperty);
        toast.current.show({ severity: "success", summary: "Uspešno", detail: "Zapis je obrisan", life: 3000 });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < properties.length; i++) {
            if (properties[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeletePropertiesDialog(true);
    };

    const deleteSelectedProperties = () => {
        let _properties = properties.filter((val) => !selectedProperties.includes(val));
        setProperties(_properties);
        setDeletePropertiesDialog(false);
        setSelectedProperties(null);
        toast.current.show({ severity: "success", summary: "Uspešno", detail: "uspelo brisanje", life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _property = { ...property };
        _property[`${name}`] = val;

        setProperty(_property);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Dodaj vilu" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Obriši" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProperties || !selectedProperties.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ime</span>
                {rowData.name}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProperty(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProperty(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Vile</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pretraga..." />
            </span>
        </div>
    );

    const propertyDialogFooter = (
        <>
            <Button label="Zatvori" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sačuvaj" icon="pi pi-check" className="p-button-text" onClick={saveProperty} />
        </>
    );
    const deletePropertyDialogFooter = (
        <>
            <Button label="Ne" icon="pi pi-times" className="p-button-text" onClick={hideDeletePropertyDialog} />
            <Button label="Da" icon="pi pi-check" className="p-button-text" onClick={deleteProperty} />
        </>
    );
    const deletePropertiesDialogFooter = (
        <>
            <Button label="Ne" icon="pi pi-times" className="p-button-text" onClick={hideDeletePropertiesDialog} />
            <Button label="Da" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProperties} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={properties}
                        selection={selectedProperties}
                        onSelectionChange={(e) => setSelectedProperties(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Prikaz {first} do {last} od ukupno {totalRecords} rezultata"
                        globalFilter={globalFilter}
                        emptyMessage="Nema rezultata."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        {/* <Column field="id" header="Id" sortable></Column> */}
                        <Column field="name" header="Ime" sortable body={nameBodyTemplate}></Column>
                        <Column headerStyle={{ width: "14%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={propertyDialog} style={{ width: "450px" }} header="Detalji" modal className="p-fluid" footer={propertyDialogFooter} onHide={hideDialog}>
                        {property.image && <img src={`assets/demo/images/property/${property.image}`} alt={property.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="name">Ime</label>
                            <InputText id="name" value={property.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !property.name })} />
                            {submitted && !property.name && <small className="p-invalid">Ime je obavezno polje.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePropertyDialog} style={{ width: "450px" }} header="Potvrda" modal footer={deletePropertyDialogFooter} onHide={hideDeletePropertyDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {property && (
                                <span>
                                    Potvrdi brisanje vile<b>{property.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePropertiesDialog} style={{ width: "450px" }} header="Potvrda" modal footer={deletePropertiesDialogFooter} onHide={hideDeletePropertiesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {property && <span>Potvrdi brisanje odabranih Vila?</span>}
                        </div>
                    </Dialog>
                    {/* <pre>{JSON.stringify(property, false, 2)}</pre> */}
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Properties, comparisonFn);
