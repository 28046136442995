import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
//import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { UnitsService } from "../service/UnitsService";
import { PropertiesService } from "../service/PropertiesService";
import { InputNumber } from "primereact/inputnumber";
import { supabase } from "../service/supabaseClient";

const Units = () => {
    let emptyProperty = {
        //id: null,
        title: "",
        max_guests: 1,
        description: "",
    };

    const [units, setUnits] = useState(null);
    const [properties, setProperties] = useState(null);
    const [unitDialog, setPropertyDialog] = useState(false);
    const [deletePropertyDialog, setDeletePropertyDialog] = useState(false);
    const [deleteUnitsDialog, setDeleteUnitsDialog] = useState(false);
    const [unit, setProperty] = useState(emptyProperty);
    const [selectedUnits, setSelectedUnits] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const unitService = new UnitsService();

    const propertiesService = new PropertiesService();

    useEffect(() => {
        unitService.getUnitsWithProperty().then((data) => {
            setUnits(data);
        });

        propertiesService.getProperties().then((data) => {
            setProperties(data);
        });

        // inicial loading units
        // getUnits();
    }, []);

    const reloadUnits = () => {
        unitService.getUnitsWithProperty().then((data) => {
            setUnits(data);
        });
    };

    const openNew = () => {
        setProperty(emptyProperty);
        setSubmitted(false);
        setPropertyDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setPropertyDialog(false);
    };

    const hideDeletePropertyDialog = () => {
        setDeletePropertyDialog(false);
    };

    const hideDeleteUnitsDialog = () => {
        setDeleteUnitsDialog(false);
    };

    const saveProperty = async () => {
        setSubmitted(true);

        if (unit.title.trim()) {
            let _units = [...units];
            let _unit = { ...unit };
            if (unit.id) {
                const index = findIndexById(unit.id);
                _units[index] = _unit;
                delete unit.properties;
                unitService.saveUnit(unit).then((data) => {
                    reloadUnits();
                    toast.current.show({ severity: "success", summary: "Uspešno", detail: "Izmene su sačuvanje", life: 3000 });
                });
            } else {
                unitService
                    .createUnit(unit)
                    .then((data) => {
                        console.log("stiglo", data[0]);
                        if (data[0]) {
                            reloadUnits();
                        }
                        toast.current.show({ severity: "success", summary: "Uspešno", detail: "Apartman je dodat", life: 3000 });
                    })
                    .catch((error) => {
                        console.log("stiglo", error);
                    });
            }

            setPropertyDialog(false);
            setProperty(emptyProperty);
        }
    };

    const editProperty = (unit) => {
        setProperty({ ...unit });
        setPropertyDialog(true);
    };

    const confirmDeleteProperty = (unit) => {
        setProperty(unit);
        setDeletePropertyDialog(true);
    };

    const deleteProperty = async () => {
        let _units = units.filter((val) => val.id !== unit.id);

        let { data, error } = await supabase.from("units").delete().eq("id", unit.id);

        if (error) {
            toast.current.show({ severity: "error", summary: "Greška", detail: "Brisanje nije uspelo", life: 3000 });
            return;
        }

        setUnits(_units);
        setDeletePropertyDialog(false);
        setProperty(emptyProperty);
        toast.current.show({ severity: "success", summary: "Uspešno", detail: "Apartman je obrisan", life: 3000 });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < units.length; i++) {
            if (units[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteUnitsDialog(true);
    };

    const deleteSelectedUnits = () => {
        let _units = units.filter((val) => !selectedUnits.includes(val));
        setUnits(_units);
        setDeleteUnitsDialog(false);
        setSelectedUnits(null);
        toast.current.show({ severity: "success", summary: "Uspešno", detail: "Apartmani su obrisani", life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _unit = { ...unit };
        _unit[`${name}`] = val;

        setProperty(_unit);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Dodaj apartman" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Obriši" icon="pi pi-trash" className="p-button-danger " onClick={confirmDeleteSelected} disabled={!selectedUnits || !selectedUnits.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        );
    };

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Oznaka</span>
                {rowData.title}
            </>
        );
    };

    const propertyBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vila</span>
                {rowData.properties?.name}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProperty(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProperty(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Apartmani</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pretraga..." />
            </span>
        </div>
    );

    const unitDialogFooter = (
        <>
            <Button label="Zatvori" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sačuvaj" icon="pi pi-check" className="p-button-text" onClick={saveProperty} />
        </>
    );
    const deletePropertyDialogFooter = (
        <>
            <Button label="Ne" icon="pi pi-times" className="p-button-text" onClick={hideDeletePropertyDialog} />
            <Button label="Da" icon="pi pi-check" className="p-button-text" onClick={deleteProperty} />
        </>
    );
    const deleteUnitsDialogFooter = (
        <>
            <Button label="Ne" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUnitsDialog} />
            <Button label="Da" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUnits} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        loading={!units}
                        value={units}
                        selection={selectedUnits}
                        onSelectionChange={(e) => setSelectedUnits(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Prikaz {first} do {last} od ukupno {totalRecords} rezultata"
                        globalFilter={globalFilter}
                        emptyMessage="Nema rezultata."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        {/* <Column field="id" header="Id" sortable></Column> */}
                        <Column field="title" header="Smeštajna jedinica" sortable body={titleBodyTemplate}></Column>
                        <Column field="properties.name" header="Vila" sortable body={propertyBodyTemplate}></Column>
                        <Column field="max_guests" header="Max broj gostiju" sortable></Column>
                        <Column headerStyle={{ width: "14%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={unitDialog} style={{ width: "450px" }} header="Unit Details" modal className="p-fluid" footer={unitDialogFooter} onHide={hideDialog}>
                        {unit.image && <img src={`assets/demo/images/unit/${unit.image}`} alt={unit.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="property_id">Vila</label>
                            <Dropdown value={unit.property_id} optionValue="id" optionLabel="name" options={properties} onChange={(e) => onInputChange(e, "property_id")} filter showClear filterBy="name" placeholder="Odaberi Vilu" />
                            {submitted && !unit.property_id && <small className="p-invalid">Vila je obavezno polje.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="title">Oznaka</label>
                            <InputText id="title" value={unit.title} onChange={(e) => onInputChange(e, "title")} required autoFocus className={classNames({ "p-invalid": submitted && !unit.name })} />
                        </div>

                        <div className="field">
                            <label htmlFor="max_guests">Max broj gostiju</label>
                            <InputNumber inputId="max_guests" value={unit.max_guests} onValueChange={(e) => onInputChange(e, "max_guests")} mode="decimal" showButtons min={0} max={100} />
                        </div>
                        <div className="field">
                            <label htmlFor="description">Opis</label>
                            <InputTextarea id="description" value={unit.description ? unit.description : ""} onChange={(e) => onInputChange(e, "description")} required rows={3} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deletePropertyDialog} style={{ width: "450px" }} header="Potvrda" modal footer={deletePropertyDialogFooter} onHide={hideDeletePropertyDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {unit && (
                                <span>
                                    Potvrdi brisanje <b>{unit.title}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUnitsDialog} style={{ width: "450px" }} header="Potvrda" modal footer={deleteUnitsDialogFooter} onHide={hideDeleteUnitsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {unit && <span>Potvrdi brisanje selektovanih apartmana?</span>}
                        </div>
                    </Dialog>
                    {/* <pre>{JSON.stringify(properties, false, 2)}</pre> */}
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Units, comparisonFn);
