import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import { supabase } from "../service/supabaseClient";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { passwordResetInProgressAtom } from "../service/state";
import { Toast } from "primereact/toast";

const PasswordReset = () => {
    const [pass, setPass] = useRecoilState(passwordResetInProgressAtom);

    const defaultValues = {
        password: "",
    };

    let history = useHistory();

    const toast = useRef(null);

    useEffect(() => {}, []);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({ defaultValues });

    const onSubmit = async (data) => {
        let { password: new_password } = data;

        const session = supabase.auth.session();
        // console.log("sesija", session);

        let { data: res, error } = await supabase.auth.api.updateUser(session.access_token, { password: new_password });

        if (error) {
            toast.current.show({ severity: "error", summary: "Greška", detail: "Lozinka nije resetovana", life: 3000 });
        }

        toast.current.show({ severity: "success", summary: "Uspešno", detail: "Nova lozinka je zapamćena", life: 3000 });
        setPass(false);
        reset();
        history.push("/home");
    };

    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    return (
        <div>
            <Toast ref={toast} />
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="field">
                    <span className="p-float-label">
                        <Controller
                            name="password"
                            control={control}
                            rules={{ required: "Password is required." }}
                            render={({ field, fieldState }) => <Password id={field.name} {...field} toggleMask className={classNames({ "p-invalid": fieldState.invalid })} header={passwordHeader} footer={passwordFooter} />}
                        />
                        <label htmlFor="password" className={classNames({ "p-error": errors.password })}>
                            Password*
                        </label>
                    </span>
                    {getFormErrorMessage("password")}
                </div>
                <Button type="submit" label="Submit" className="mt-2" />
            </form>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PasswordReset, comparisonFn);
